<template>
    <div>
        <div>
            <Breadcrumb content="产品管理" />
        </div>
        <div>
            <el-button-group>
                <el-button @click="refresh" type="primary" icon="el-icon-refresh-left" title="刷新"></el-button>
                <el-button type="warning" @click="$router.push('productAdd')">新增产品</el-button>
            </el-button-group>
        </div>
        <div>
            <el-collapse style="border: #ecf0f5 1px solid;margin-bottom: 5px">
                <el-collapse-item title="筛选">
                    <el-form ref="filter" v-model="filterForm" label-width="80px" style="padding-left: 5px">
                        <el-row>
                            <el-col :span="4">
                                <el-form-item label="审核状态">
                                    <el-select v-model="filterForm.type" class="m-full">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option v-for="(v, k) in $webConfig.productTypes" :key="k" :label="v.name"
                                            :value="v.type">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="名称">
                                    <el-input placeholder="输入名称模糊搜索" @keyup.enter.native="filterList"
                                        v-model="filterForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <el-button type="primary" @click="filterList">筛选</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div>
            <el-table :data="formatData" border size="small" stripe style="width: 100%">
                <el-table-column prop="created_at" label="添加时间" width="180">
                </el-table-column>
                <el-table-column prop="typeName" label="产品类型">
                </el-table-column>
                <el-table-column prop="name" label="名称">
                </el-table-column>
                <el-table-column prop="no" label="编号">
                </el-table-column>
                <el-table-column label="卡牌图片" width="150">
                    <template slot-scope="scope">
                        <el-image style="height: 40px;" :src="scope.row.img + '?x-oss-process=image/resize,h_300,w_300'"
                            fit="contain" :preview-src-list="[scope.row.img]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleEdit(scope.row)">
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="padding:20px 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/admin/breadcrumb'

export default {
    name: "index",
    components: { Breadcrumb },
    data: function () {
        return {
            pageSize: this.$webConfig.pages.pageSize,
            pageSizes: this.$webConfig.pages.pageSizes,
            currentPage: 1,
            tableData: [],
            total: 0,
            filterForm: {
                type: "",
                name: "",
            },
        }
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        handleEdit(row) {
            let id = row.id;
            this.$router.push({ path: "/admin/productEdit", query: { id } })
        },
        getList: function () {
            let page = this.currentPage;
            let limit = this.pageSize;
            let type = this.filterForm.type;
            let name = this.filterForm.name;
            this.$axios.post("site/siteProduct/getList", { page, limit, type, name })
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    }
                })
        },
        refresh: function () {
            this.currentPage = 1;
            this.getList();
            this.$message.success("列表刷新成功")
        },
        filterList: function () {
            this.currentPage = 1;
            this.getList();
        }
    },
    created() {
        this.getList();
    },
    computed: {
        formatData() {
            let data = [];
            this.tableData.forEach((v) => {
                v.typeName = this.$webConfig.productTypes[v.type].name;
                data.push(v);
            });
            return data;
        }
    },
}
</script>

<style scoped></style>
